.kwp-admin-userinfo {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: $padding-s $padding-l;
	background-color: $light-bg-color;
	border-top: 1px solid $line-color;
	width: 100%;
	white-space: nowrap;

	.username {
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: calc(#{$nav-width} * 0.7);
		display: inline-block;
		float: left;
		line-height: 2 * $padding-s + 2;
	}

	button {
		float: right;
	}
}