.kwp-admin-component-hasone {
	label {
		display: block;
		width: 100%;
		padding: $padding-s 0;
		font-weight: bold;
	}

	.entity {
		span {
			padding: $padding-xs;
			border: 1px solid $line-color;
			display: inline-block;
			width: 80%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		button {
			vertical-align: top;
		}
	}

}