.kwp-admin-component-input {
	label, input, textarea {
		display: block;
		width: 100%;
		resize: vertical;
	}

	label {
		padding: $padding-s 0;
		font-weight: bold;
	}

	input[type="checkbox"] {
		width: auto;
	}
}