.models-table-wrapper {
	& > div {
		& > .pull-left {
			width: 85%;
		}

		& > .pull-right {
			width: 10%;
		}
	}

	.globalSearch {
		width: 100%;
		display: none;

		span, label {
			display: none;
		}

		input {
			width: 100%;
		}

		margin-bottom: $padding-l;
	}

	.columns-dropdown {
		display: none;
	}

	table.table {
		width: 100%;
		text-align: left;
		border-spacing: 0;
		border-collapse: separate;
		border: 1px solid $line-color;

		td, th {
			padding: $padding-s;
		}

		thead {
			input {
				width: 100%;
			}

			tr:first-child {
				th {
					background-color: $hover-color;
					border-bottom: 1px solid $line-color;
				}
			}

			tr:nth-child(2) {
				th {
					background-color: $light-bg-color;
					border-bottom: 1px solid $line-color;
				}
			}
		}

		tbody {
			td {
				border-bottom: 1px solid $line-color;
			}

			tr.selected-row {
				background-color: $highlight-color;
			}
		}
	}

	.table-footer {
		display: none;

		.clearFilters {
			display: none;
		}

		.changePageSize {
			display: none;
		}

		.table-summary, .table-nav {
			display: inline-block;
		}

		.table-summary {
			float: left;
		}

		.table-nav {
			float: right;
		}

		.col-md-2.col-sm-2.col-xs-2 {
			display: none;
		}

		&:after {
			display: block;
			content: " ";
			clear: both;
		}
	}

	.table-header {
		.clearFilterIcon {
			display: none;
		}
	}

	.glyphicon {
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;

		&:after {
			display: inline-block;
		}

		&.glyphicon-menu-right:after {
			content: "\f0da";
		}

		&.glyphicon-menu-left:after {
			content: "\f0d9";
		}

		&.glyphicon-chevron-right:after {
			content: "\f051";
		}

		&.glyphicon-chevron-left:after {
			content: "\f048";
		}
	}

	.btn {
		@extend button;
		display: inline-block;
	}
}