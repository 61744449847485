.kwp-admin-cover {
	&.show {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to bottom, $hover-color 0%, #FFF 100%);
		transition: all 0.25s;
		opacity: 1;
		z-index: 700;
	}

	&:not(.show) {
		opacity: 0;
		pointer-events: none;
	}
	
}