.kwp-admin-component-hasmany {
	label {
		display: block;
		width: 100%;
		padding: $padding-s 0;
		font-weight: bold;
	}

	.entities {
		list-style-type: none;
		display: block;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			padding: $padding-xs;
			background-color: $light-bg-color;
			border: 1px solid $line-color;
			cursor: pointer;
			margin-bottom: $padding-xs * 0.5;

			span.icon {
				margin-left: $padding-xs;
			}

			i {
				color: $line-color;
			}

			&:hover {
				background-color: $hover-color;

				i:hover {
					color: $font-color;
				}
			}
		}
	}

}