.kwp-admin-form-action {
	display: inline-block;

	.action-wrapper {
		padding: $padding-l;
		cursor: pointer;
		width: auto;

		&:hover {
			background-color: $hover-color;
		}

		.icon, .label {
			display: block;
			text-align: center;
		}

		.icon {
			font-size: 2em;
		}
	}
}