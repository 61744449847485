.kwp-admin-modal {
	position: fixed;
	width: 500px;
	background-color: #FFF;
	border: 1px solid $line-color;
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
	transition: opacity 0.25s, transform 0.25s, height 0.25s;
	opacity: 1;
	transform: translate(0, 0);
	pointer-events: auto;
	z-index: 1000;
	

	&:not(.show) {
		transform: translate(0, -30px);
		opacity: 0;
		pointer-events: none;
	}

	.head {
		background: linear-gradient(to bottom, $hover-color 0%, #FFF 100%);
		border-bottom: 1px solid $line-color;

		.title, .close {
			padding: $padding-s;
			display: inline-block;
		}

		.close {
			float: right;
			cursor: pointer;

			&:hover {
				background-color: $hover-color;
			}
		}
	}

	.body {
		padding: $padding-s;

		.kwp-admin-modal-footer {
			padding: $padding-s;
			margin: -$padding-s;
			margin-top: $padding-s;
			border-top: 1px solid $line-color;
		}
	}
}