html {
	font-size: $font-size;
}

body {
	font-family: $font-family;
	color: $font-color;
	padding-left: 300px;
}

* {
	box-sizing: border-box;
}

input, button {
	padding: $padding-xs;
	border: 1px solid $font-color;

	&:focus:not(:disabled) {
		border-color: #000;
		background-color: $highlight-color;
	}
}

button {
	background-color: $light-bg-color;
	color: $font-color;
	
	&:hover {
		background-color: $highlight-color;
	}

	&:active {
		box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.5);
	}
}

.error {
	color: red;
}

.glyphicon {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.glyphicon-triangle-bottom::after {
		content: "\f0d7";
	}

	&.glyphicon-triangle-top::after {
		content: "\f0d8";
	}
}