.kwp-admin-component-datagrid {
	label {
		display: block;
		width: 100%;
		padding: $padding-s 0;
		font-weight: bold;
	}

	.table {
		border: 1px solid $line-color;

		.row {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			border-bottom: 1px solid $line-color;

			&.head {
				.column {
					padding: $padding-s; 
					background-color: $light-bg-color;
					font-weight: bold;

					&.action {
						cursor: default;

						i {
							opacity: 0;
							pointer-events: none;
						}
					}
				}
			}

			&.foot {
				border-bottom-width: 0px;
			}

			.column {
				flex: 1;
				border-right: 1px solid $line-color;

				&.action {
					width: 40px;
					flex: 0;
					background-color: $light-bg-color;
					padding: $padding-s; 
					cursor: pointer;

					&:hover {
						background-color: $hover-color;
					}

					&.sort {
						white-space: nowrap;
						width: 57px;
					}
				}

			}
		}
	}
}