$font-color: #333;
$font-size: 14px;
$font-family: sans-serif;

$padding-xs: .5 * $font-size;
$padding-s: 1 * $font-size;
$padding-m: 1.5 * $font-size;
$padding-l: 2 * $font-size;

$line-color: lighten($font-color, 50%);
$hover-color: lighten($font-color, 77%);
$light-bg-color: lighten($font-color, 79%);

$error-color: #ef9a9a;

$tab-height: 3.5 * $font-size;

$highlight-color: rgba(255,255,0, 0.1);

$nav-width: 300px;
