$head-height: $tab-height + $padding-l + $padding-l + $font-size + $font-size + $padding-s + $padding-s + $font-size + $padding-l + $padding-s;

.kwp-admin-form {

	&:not(.active) {
		display: none;
	}
	
	.scrollframe {
		height: calc(100vh - #{$head-height});
		overflow-y: auto;
	}
	.table-position {
		position: fixed;
		bottom: 0;
		left: $nav-width;
		padding: $padding-s $padding-l;
		background-color: $light-bg-color;
		border-top: 1px solid $line-color;
		width: calc(100% - #{$nav-width});

		.navigation {
			input {
				width: 80px;
			}

			span {
				&:first-child {
					margin-right: $padding-m;
				}

				&:last-child {
					margin-left: $padding-m;
				}
			}
		}

		.view-switch {
			float: right;
			display: inline-block;
			font-size: 2em;

			span {
				color: $line-color;

				&.active {
					color: $font-color;
				}
			}
		}

	}

	& > .components, & > .navigation {
		margin: 0 -$padding-l;
	}

	&.navigation-view {
		& > .components {
			display: none;
		}
	}

	&.components-view {
		& > .navigation {
			display: none;
		}

		.empty-state {
			i {
				display: block;
				font-size: 3em;
				margin-bottom: 0.5em;
			}

			display: block;
			padding: 2em;
			font-size: 2em;
			text-align: center;
			font-style: italic;
			color: $line-color;
		}
	}
}