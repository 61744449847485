.kwp-admin-component-datagrid-cell-hasone {
	.entity {
		padding: $padding-s;
		display: inline-block;
	}

	.pick, .remove {
		display: none;
	}

	&:hover {
		.pick, .remove {
			display: inline-block;
		}
	}
}