.kwp-admin-form-error {
	border: 0px solid darken($error-color, 30%);
	background-color: $error-color;
	color: get-contrast-color($error-color);
	margin: $padding-l;
	margin-bottom: 0;
	max-height: 0;
	transition: all 0.25s;
	overflow: hidden;

	&.show {
		max-height: 200px;
		border-width: 2px;
	}

	.inner {
		padding: $padding-m;
	}
}