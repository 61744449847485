.kwp-admin-login-form {
	label, input {
		display: block;
		width: 100%;
	}

	label {
		padding: $padding-s 0;
	}

	input, .error {
		margin-bottom: $padding-s;
	}
}