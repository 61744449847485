.kwp-admin-window-tabs {
	height: $tab-height;
	width: 100%;
	box-shadow: inset 0px -10px 5px -10px rgba(0,0,0,0.5);
	background-color: $light-bg-color;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		font-size: 0;
		height: $tab-height;
		overflow: hidden;

		li {
			display: inline-block;
			height: $tab-height;
			width: 200px;
			padding: 0 $padding-s;
			cursor: pointer;
			font-size: 1rem;
			position: relative;
			border-right: 1px solid $line-color;

			&:hover {
				background: linear-gradient(to bottom, $hover-color 0%, transparent 100%);

				span.title {
					max-width: 90%;
				}

				span.close {
					opacity: 1;
				}
			}

			&.active {
				background: linear-gradient(to bottom, $hover-color 0%, #FFF 100%);
				z-index: 500;
			}

			span.title, span.close {
				line-height: $tab-height;
				white-space: nowrap;
				overflow: hidden;
				transition: all 0.25s;
			}

			span.title {
				max-width: 100%;
				display: inline-block;
				text-overflow: ellipsis;
			}

			span.close {
				float: right;
				opacity: 0;
			}
		}
	}
}