.kwp-admin-component {
	display: block;
	float: left;
	padding: $padding-l;

	&.size1 {
		width: 25%;
	}

	&.size2 {
		width: 50%;
	}

	&.size3 {
		width: 75%;
	}

	&.size4 {
		width: 100%;
	}
}