.kwp-admin-nav {
	position: fixed;
	left: 0;
	top: 0;
	width: $nav-width;
	border-right: 1px solid $line-color;
	height: 100%;
	overflow: hidden;
	background-color: $light-bg-color;

	.scrollcontainer {
		padding: $padding-l;
		overflow-y: auto;
		max-height: calc(100vh - (4 * #{$padding-s}));

		img.logo {
			max-width:100%;
			max-height:100%;
		}

		span.title {
			display: block;
			font-size: 1.5em;
			margin: 1em 0;
		}

		ul.nav {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				padding: $padding-m;
				cursor: pointer;

				&:not(:last-child) {
					border-bottom: 1px solid $line-color;
				}

				&:hover {
					background-color: $hover-color;
				}

				.nav-title {
					font-size: 1.2em;
				}
				
			}
		}
	}
}